












import Vue from "vue";
import { WPService } from "../services/WPService";

const BlogSingle = Vue.extend({
  mounted: function () {
    const slug = this.$route.params.slug;
    WPService.loadPostBySlug(slug).then(
      (data) => (this.post = data[0]),
      (error) => console.log(error)
    );
  },
  data: () => {
    return {
      post: "",
    };
  },
  updated: function () {
    window.Prism.highlightAll();
  },
});
export default BlogSingle;
